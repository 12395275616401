<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Fulltime -- Attendance Review"/>

        <v-spacer></v-spacer>

        
        <v-btn dark color="primary" text @click="exportAttendanceReview()">Export</v-btn>

      </v-toolbar> 



      <v-divider></v-divider>

        <template>
            <v-dialog v-model="showSlotDialog" v-if="selItem != null">
                <v-card>

                    <v-card-text>
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td>Store Name</td>
                                        <td>{{ selItem.store_name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee Name</td>
                                        <td>{{ selItem.employee.name_th }}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee No</td>
                                        <td>{{ selItem.employee.employee_no }}</td>
                                    </tr>
                                    <tr>
                                        <td>Employee Phone</td>
                                        <td>{{ selItem.employee.phone }}</td>
                                    </tr>
                                    <tr>
                                        <td>Slot Date</td>
                                        <td>{{ selSlot.slot_date }}</td>
                                    </tr>
                                    <tr>
                                        <td>Start</td>
                                        <td>{{ selItem.slot_start }}</td>
                                    </tr>
                                    <tr>
                                        <td>End</td>
                                        <td>{{ selItem.slot_end }}</td>
                                    </tr>
                                    <tr>
                                        <td>Working Hour</td>
                                        <td>{{ selItem.online_hour }}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>{{ selItem.slot_amount }}</td>
                                    </tr>
                                    <tr>
                                        <td>Check In Status</td>
                                        <td>
                                            <v-chip small v-if="selItem[selSlot.slot_date].rostering_slot_timesheet == null"> N/A </v-chip>
                                            <v-chip small color="green" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.start_status_id == 1"> IN </v-chip>
                                            <v-chip small color="green" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.start_status_id == 2"> OUT </v-chip>
                                            <v-chip small color="red" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.start_status_id == 3"> AUTO </v-chip>
                                            <v-chip small color="orange" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.start_status_id == 4"> IN LATE </v-chip>
                                            <v-chip small color="orange" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.start_status_id == 5"> OUT LATE </v-chip>
                                
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Check In Time</td>
                                        <td>{{ selItem[selSlot.slot_date].rostering_slot_timesheet ? moment(selItem[selSlot.slot_date].rostering_slot_timesheet.start_timesheet_date).format("YYYY-MM-DD HH:mm:ss") : "---"}}</td>
                                    </tr>
                                    <tr>
                                        <td>Check out Status</td>
                                        <td>
                                            <v-chip small v-if="selItem[selSlot.slot_date].rostering_slot_timesheet == null"> N/A </v-chip>
                                            <v-chip small color="green" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.end_status_id == 1"> IN </v-chip>
                                            <v-chip small color="green" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.end_status_id == 2"> OUT </v-chip>
                                            <v-chip small color="red" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.end_status_id == 3"> AUTO </v-chip>
                                            <v-chip small color="orange" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.end_status_id == 4"> IN LATE </v-chip>
                                            <v-chip small color="orange" v-if="selItem[selSlot.slot_date].rostering_slot_timesheet && selItem[selSlot.slot_date].rostering_slot_timesheet.end_status_id == 5"> OUT LATE </v-chip>
                                
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Check Out Time</td>
                                        <td>{{ selItem[selSlot.slot_date].rostering_slot_timesheet ? moment(selItem[selSlot.slot_date].rostering_slot_timesheet.end_timesheet_date).format("YYYY-MM-DD HH:mm:ss") : "---"}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeSlotPreview">Cancel</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
        </template>
      <div class="content-wrap">

            <v-row justify="end" >
                <v-col cols="10" sm="8" md="6" >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>

           <v-row>
                
              <v-col cols="12" sm="12" md="12"  >
                    <v-data-table
                        :headers="headers"
                        :items="rosters"
                        :items-per-page="100"
                        :footer-props="footerProps"
                        class="elevation-1"
                        :sort-by="['store_name']"
                        :sort-desc="[true]"
                        :search="search"

                        dense
                        >

                        <template
                            v-slot:item="{ item }"
                        >
                            <tr>
                                <td>{{ item.store_name }}</td>

                                <td>{{ item.slot_start }}</td>
                                <td>{{ item.slot_end }}</td>
                                <td>{{ item.employee.employee_no }}</td>
                                <td>{{ item.employee.name_th }}</td>
                                <td>{{ item.employee.phone }}</td>
                                <td v-for="slot in headersDate" :key="slot.slot_date" v-bind:class="isToday(slot.slot_date)" >
                                    <v-chip small v-if="item[slot.slot_date].planing == 'N/A'"> N/A </v-chip>
                                    <v-chip small color="primary" v-if="item[slot.slot_date].planing == 'PLAN'" @click="showSlotPreview(item, slot)"> PLAN </v-chip>
                                    <v-chip small color="green" v-if="item[slot.slot_date].planing == 'OUT'" @click="showSlotPreview(item, slot)"> OUT </v-chip>
                                    <v-chip small color="green" v-if="item[slot.slot_date].planing == 'IN'" @click="showSlotPreview(item, slot)"> IN </v-chip>
                                    <v-chip small color="orange" v-if="item[slot.slot_date].planing == 'OUT LATE'" @click="showSlotPreview(item, slot)"> OUT LATE </v-chip>
                                    <v-chip small color="orange" v-if="item[slot.slot_date].planing == 'IN LATE'" @click="showSlotPreview(item, slot)"> IN LATE </v-chip>
                                    <v-chip small color="red" v-if="item[slot.slot_date].planing == 'OFF'" @click="showSlotPreview(item, slot)"> OFF </v-chip>
                                    <v-chip small color="red" v-if="item[slot.slot_date].planing == 'AUTO'" @click="showSlotPreview(item, slot)"> AUTO </v-chip>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>

              </v-col>
          </v-row>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';

export default {
    name: 'FulltimePaymentSchedule',
    data: () => ( {
        selSchedule : null,
        selItem : null,
        selSlot : null,
        showSlotDialog : false,
        rosters : [
        ],
        headersDate : [],
        search : "",
        payment_records : [],
        footerProps: {'items-per-page-options': [50, 100, 500]},
        today : null,
        headers: [
            
        ],

        menu: {
            start_date: false,
            end_date: false,
            paid_date: false
        },
        newItem: {
            name: null,
            start_date: null,
            end_date: null,
            paid_date: null
        },
        validate_rules : {
            required: v => !!v || 'This field is required',
            filesize:  v => !v || v.size < 2048*1024 || 'Updload size should be less than 2 MB!',
            filetype:  v => {
                if(v){
                var ext = v.name.split('.').pop();
                    return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
                } else {
                    return 'This field is required'
                }
            }
        },
    }),
    components: {
        pageHeading
    },
    methods: {
         ...mapActions(['genCan', 'getOverallRostering', 'genExcelFile', 'downloadFromBase64']),
        isToday(inp_date) {
            const moment = require('moment-timezone');

            let ret_class = [];
            if (inp_date == moment().tz('Asia/Bangkok').format("YYYY-MM-DD"))
            {
                ret_class.push('amber');
                ret_class.push('lighten-4');
            }
            return ret_class;
        },

        showSlotPreview(item, slot) {
            this.showSlotDialog = true;
            console.log(item);
            console.log(slot);
            this.selItem = item;
            this.selSlot = slot;
        },
        closeSlotPreview() {
            this.showSlotDialog = false;
            this.selItem = null;
            this.selSlot = null;
        },

        async reloadData() {
            const lodash = require('lodash');
            const moment = require('moment-timezone');


            this.today = moment().tz("Asia/Bangkok");

            this.headers = [
                { text: 'Store', value: 'store_name', width: 200 },
                { text: 'Start', value: 'slot_start', groupable: false},
                { text: 'End', value: 'slot_end', groupable: false },
                { text: 'Employee No', value: 'employee.employee_no', width: 120 },
                { text: 'Name', value: 'employee.name_th', width: 120 },
                { text: 'Tel', value: 'employee.phone' }
            ]
            this.headersDate = [];

            let start_date = moment().tz("Asia/Bangkok").subtract(1, "days").format("YYYY-MM-DD");
            let end_date = moment().tz("Asia/Bangkok").add(4, "days").format("YYYY-MM-DD");

            for (var m = moment(start_date); m.isSameOrBefore(end_date); m.add(1, 'days')) {
                this.headers.push({ text: m.format('D MMM'), value: m.format("YYYY-MM-DD"), groupable: false });
                this.headersDate.push({slot_date : m.format("YYYY-MM-DD")})
            }

            this.rosters = await this.getOverallRostering();
        },
        async exportAttendanceReview() {
            const lodash = require('lodash');

            let col_title =  lodash.reduce(this.headers, function(result, item) { 
                result[item.value] = item.text;
                return result;
            }, {});

            let col_width =  lodash.reduce(this.headers, function(result, item) { 
                result[item.value] = item.width ? item.width/10 : 10;
                return result;
            }, {});
            let local_header_dates = this.headersDate;
            let data = lodash.map(this.rosters, function(item) {

                let date_data = lodash.reduce(local_header_dates, function(result, slot_date) {
                    result[slot_date.slot_date] =  item[slot_date.slot_date].planing;
                    return result;
                }, {});
                return {
                    store_name : item.store_name,
                    slot_start : item.slot_start,
                    slot_end : item.slot_end,
                    "employee.employee_no" : item.employee.employee_no,
                    "employee.name_th" : item.employee.name_th,
                    "employee.phone" : item.employee.phone,
                    ...date_data
                }
            });

            let excel_b64 = await this.genExcelFile({ 
                inp_data_json : data,
                inp_header : col_title,
                col_width : col_width,
                sheet_name : "Attendance"
            });
            await this.downloadFromBase64({
                filename : "AttendanceReview.xlsx", 
                text_b64 : excel_b64,
                type : "application/vnd.ms-excel"
            });

        }
    },
    async beforeMount() {
        this.genCan();
        this.reloadData();
    },

    computed: {
        ...mapGetters(['canDo','allCategories'])
    },

    mounted() {
    }
};
</script>